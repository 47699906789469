<template>
  <div>
    <f-page-header>
      <h1>{{ $t('labels.dashboard') }}</h1>
    </f-page-header>
    <f-container topBottom>
      {{ $t('texts.dash.dev') }}<br />
      <br />
      <!-- <v-card elevation="0" v-if="showDiv">
        <v-card-text>
          scorm file sample<br />
          <br />
          <button class="classer-btn" @click="launch">Launch</button> |
          <button class="classer-btn" @click="clearData">Clear</button><br />
          <br />
          <iframe id="classer"></iframe><br />
          <br />
          <div id="debug"></div>
          <br />
          <br />
          <fieldset>
            <legend>
              Options
              <b
                >(
                <a
                  href="javascript:void(0);"
                  onclick="Utils.toggleDisplay('optionSet'); return false;"
                  >Toggle</a
                >
                )</b
              >
            </legend>
            <div id="optionSet">
              <div class="tblRowInstructions">
                Enter the name of the storage to use for this session (or to
                clear using the option above):
              </div>
              <div class="tblRow">
                <div class="tblRowHeader">Storage Name:</div>
                <input type="text" id="cookieNameAlt" size="50" />
                <a
                  href="javascript:void(0);"
                  onclick="Utils.genNewSessionName(); return false;"
                  class="actionLink"
                  >Generate New Name</a
                >
              </div>
              <div class="tblRowInstructions">
                Enter the full/relative path/filename for the SCO's launch file.
                The default is currently &quot; &quot;. Populated automatically
                if IMS manifest is present:
              </div>
              <div class="tblRow">
                <div class="tblRowHeader">Launch File:</div>
                <input
                  type="text"
                  id="launchFileAlt"
                  size="30"
                  value="scorm_file_001/index_lms.html"
                />
              </div>
              <div class="tblRowInstructions">
                Override the default window options (Default values can be
                changed in the source):
              </div>
              <div class="tblRow">
                <div class="tblRowHeader">Width:</div>
                <input type="text" id="winW" size="10" />
              </div>
              <div class="tblRow">
                <div class="tblRowHeader">Height:</div>
                <input type="text" id="winH" size="10" />
              </div>
              <div class="tblRow">
                <div class="tblRowHeader">Features:</div>
                <div class="inlineOption">
                  <label for="wToolbarOption">Toolbar</label>
                  <input
                    type="checkbox"
                    id="wToolbarOption"
                    onclick="Utils.toggleWindowOption('wToolbar',this);"
                  />
                </div>
                <div class="inlineOption">
                  <label for="wTitlebarOption">Titlebar</label>
                  <input
                    type="checkbox"
                    id="wTitlebarOption"
                    onclick="Utils.toggleWindowOption('wTitlebar',this);"
                  />
                </div>
                <div class="inlineOption">
                  <label for="wLocationOption">Location</label>
                  <input
                    type="checkbox"
                    id="wLocationOption"
                    onclick="Utils.toggleWindowOption('wLocation',this);"
                  />
                </div>
                <div class="inlineOption">
                  <label for="wStatusOption">Statusbar</label>
                  <input
                    type="checkbox"
                    id="wStatusOption"
                    onclick="Utils.toggleWindowOption('wStatus',this);"
                  />
                </div>
                <div class="inlineOption">
                  <label for="wScrollbarsOption">Scrollbars</label>
                  <input
                    type="checkbox"
                    id="wScrollbarsOption"
                    onclick="Utils.toggleWindowOption('wScrollbars',this);"
                  />
                </div>
                <div class="inlineOption">
                  <label for="wMenubarOption">Menubar</label>
                  <input
                    type="checkbox"
                    id="wMenubarOption"
                    onclick="Utils.toggleWindowOption('wMenubar',this);"
                  />
                </div>
                <div class="inlineOption">
                  <label for="wResizableOption">Resizable</label>
                  <input
                    type="checkbox"
                    id="wResizableOption"
                    onclick="Utils.toggleWindowOption('wResizable',this);"
                  />
                </div>

                <div class="optionGroup">
                  <a
                    href="#"
                    onclick="Utils.enableAllWindowOptions();return false;"
                    >Select All</a
                  >
                  |
                  <a
                    href="#"
                    onclick="Utils.disableAllWindowOptions();return false;"
                    >Deselect All</a
                  >
                </div>
              </div>

              <div class="tblRowInstructions">
                SimpleAPI behavioral settings:
              </div>
              <div class="tblRow">
                <input
                  type="checkbox"
                  id="closeOnFinishOption"
                  onclick="Utils.toggleCloseOnFinishOption(this.checked);"
                />
                <label for="closeOnFinishOption">Close SCO on LMSFinish</label>
              </div>
              <div class="tblRow">
                <input
                  type="checkbox"
                  id="toggleEmbeddedOption"
                  onclick="Utils.toggleEmbeddedParam(this.checked);"
                />
                <label for="toggleEmbeddedOption"
                  >Launch with custom search string appended to launch file:
                </label>
                <input type="text" id="searchString" size="50" />
              </div>
              <div class="tblRow">
                <input
                  type="checkbox"
                  id="toggleCustomKeyValueOption"
                  onclick="Utils.toggleCustomKeyValueOption(this.checked);"
                />
                <label for="toggleCustomKeyValueOption"
                  >Launch with custom key/value pair injected into API object: </label
                ><input type="text" id="customApiKey" size="30" /> =
                <input type="text" id="customApiValue" size="30" />
              </div>
              &nbsp;
            </div>
          </fieldset>
        </v-card-text>
      </v-card> -->
    </f-container>
  </div>
</template>

<script>
// import { init, Utils } from "../../helpers/scorm";

export default {
  data: () => ({
    showDiv: false,
  }),
  mounted() {
    this.$router.push({
      name: 'course-list',
    });
    // if (/^cursos/.test(window.location.host)) {
    //   return;
    // }
    // this.showDiv = true;
    // const hasScript = document.querySelector("#scorm-script");
    // if (!hasScript) {
    //   var head = document.getElementsByTagName("head")[0];
    //   var script = document.createElement("script");
    //   script.type = "text/javascript";
    //   script.src = `${window.location.href}scorm.js`;
    //   script.onload = function() {
    //     Utils.clearCookieData();
    //   };
    //   script.setAttribute("id", "scorm-script");
    //   head.appendChild(script);
    // } else {
    //   Utils.clearCookieData();
    // }
  },
  methods: {
    launch() {
      Utils.launchSCO();
    },
    clearData() {
      Utils.clearCookieData();
    },
  },
};
</script>

<style lang="css">
#classer {
  width: 100%;
  height: 500px;
  border: none;
  background-color: #22201f;
}
#debug {
  height: 400px;
  overflow-y: auto;
  overflow-x: auto;
  scroll-behavior: auto;
  font-size: 0.8rem;
}

#optionSet {
  padding: 20px;
}
</style>
